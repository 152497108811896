import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
// import { resetAlertMessageAction } from 'src/actions/accountAction';
import { useNavigate } from 'react-router-dom';

function HandleAlert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbars = () => {

    const navigate = useNavigate()
    // const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }
        // dispatch(resetAlertMessageAction());
        setOpen(false);
    };

    const task = useSelector((state:any) => {
        return state.task
    })

    useEffect(() => {
        if (task.alert !== ''){
            setOpen(true);
        }
    },[task,navigate])

    return (    
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <HandleAlert onClose={handleClose} severity={task.alert}>
            {task.message}
            </HandleAlert>
        </Snackbar>
    );
}

export default Snackbars;
