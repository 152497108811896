const initState = {
    eventList: [],
    message: '',
    alert: '',
    loading: false,
};

const calendarReducer = (state:any = initState, action: any) => {
    switch (action.type) {
        case "CLOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "CRESET_ALERT_MESSAGE": {
        state = {
            ...state,
            message: '',
            alert: '',
            loading: false
        };
        break;
        }
        case "GET_CALENDAR_EVENT_LIST_SUCCESS": {
            state = {
                ...state,
                eventList: action.payload,
                loading: false
            };
            break;
        }
        case "POST_CALENDAR_EVENT_SUCCESS": {
            const data = action.payload
            state = {
                ...state,
                eventList:[data, ...state.eventList],
                message: 'Task Create Success',
                alert: 'success',
                loading: false  
            };
            break;
        }
        case "UPDATE_CALENDAR_EVENT_SUCCESS": {
            const data = action.payload
            state = {
                ...state,
                eventList: state.eventList.map((item:any)=>item.id===data.id ? data : item),
                message: 'Event Update Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "DELETE_CALENDAR_EVENT_SUCCESS": {
            state = {
                ...state,
                eventList: state.eventList.filter((item:any)=>item.id!==action.payload),
                message: 'Event Delete Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "CERROR_MESSAGE": {
            const { errmessage, alert } = action.payload
            state = {
                ...state,
                message: errmessage,
                alert: alert,
                loading: false
            };
            break;
        }
        case "CERROR": {
            state = {
                ...state,
                message: 'error',
                loading: false
            };
            break;
        }

        default:
            state = { ...state };
            break;
    }
    return state;
};
export default calendarReducer;
