import React,{useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  Box
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Logo from 'src/components/Logo';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';


const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  },
  title: {
    marginLeft: 10,
  },
  ico:{
    marginRight:40
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [theme, setTheme] = useState('default');


  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Typography variant="h6" className={classes.title}>
          DailyLabs
        </Typography>
        <Box flexGrow={1} />
        {theme==='dark'?
        <IconButton color="inherit" className={classes.ico} onClick={() =>{setTheme('default'); dispatch({type:'THEME_SELECTED',payload:'default'});}}>
          <Brightness7Icon />
        </IconButton>
        :
          <IconButton color="inherit" className={classes.ico} onClick={() =>{setTheme('dark'); dispatch({type:'THEME_SELECTED',payload:'dark'});}}>
            <Brightness4Icon />
          </IconButton>
        }
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
