import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors
} from '@material-ui/core';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { baseURL } from 'src/services/serverCallAxiosInstance';


const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  avatarBlue: {
    backgroundImage: colors.blue
  },
  avatarGreen: {
    backgroundImage: colors.green
  },
  avatarOrange: {
    backgroundImage: colors.orange
  },
  avatarIndigo: {
    backgroundImage: colors.indigo
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

const NotificationList = props => {
  const { notifications, className, ...rest } = props;

  const classes = useStyles();

  // const avatars = {
  //   order: (
  //     <Avatar className={classes.avatarBlue}>
  //       <PaymentIcon />
  //     </Avatar>
  //   ),
  //   user: (
  //     <Avatar className={classes.avatarOrange}>
  //       <PeopleIcon />
  //     </Avatar>
  //   ),
  //   project: (
  //     <Avatar className={classes.avatarGreen}>
  //       <StoreIcon />
  //     </Avatar>
  //   ),
  //   feature: (
  //     <Avatar className={classes.avatarIndigo}>
  //       <CodeIcon />
  //     </Avatar>
  //   )
  // };

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      disablePadding
    >
      {notifications.map((notification, i) => (
        <ListItem
          className={classes.listItem}
          // component={RouterLink}
          divider={i < notifications.length - 1}
          key={i}
          // to="#"
        >
          <ListItemAvatar>
            {/* {avatars[baseURL+notification.avatar]} */}
            <Avatar className={classes.avatarBlue} src={baseURL+`${notification.avatar}`}/>
          </ListItemAvatar>
          <ListItemText
            primary={notification.title}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={moment(notification.updated_at).fromNow()}
          />
          {/* <ArrowForwardIcon className={classes.arrowForwardIcon} /> */}
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
};

export default NotificationList;
