import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  MenuItem,
  ListItemIcon,
  // Button,
  TextField,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import NavItem from './NavItem';
import {motion} from "framer-motion"
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {baseURL} from 'src/services/serverCallAxiosInstance'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 48,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const STATUS = [
  {"status": "Available", "color": "blue"},
  {"status": "Away", "color": "yellow"},
  {"status": "Busy", "color": "red"},
  {"status": "Invisible", "color": "inherit"},
]

const NavBar = ({ onMobileClose, openMobile, accountR, handleUpdateAccount }) => {
  const classes = useStyles();
  const location = useLocation();
  const userAccount = accountR.userAccount

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const Adminitems = [
    // {
    //   href: '/app/organization',
    //   icon: AccountTreeIcon,
    //   title: 'organization'
    // },
    {
      href: '/app/reports',
      icon: ScatterPlotIcon,
      title: 'Reports'
    },
    {
      href: '/app/dashboard',
      icon: DashboardIcon,
      title: 'Dashboard'
    },
    {
      href: '/app/module-tasks',
      icon: AssignmentIcon,
      title: 'My Tasks'
    },
    {
      href: '/app/projects',
      icon: FolderIcon,
      title: 'Projects'
    },
    {
      href: '/app/modules',
      icon: ViewModuleIcon,
      title: 'Modules'
    },
    {
      href: '/app/employees',
      icon: PeopleIcon,
      title: 'Employees'
    },  
    {
      href: '/app/calendar',
      icon: EventNoteIcon,
      title: 'Calendar'
    },
    {
      href: '/app/account',
      icon: AccountCircleIcon,
      title: 'Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: 'Settings'
    },
  ];

  const items = [
    // {
    //   href: '/app/organization',
    //   icon: AccountTreeIcon,
    //   title: 'organization'
    // },
    {
      href: '/app/dashboard',
      icon: DashboardIcon,
      title: 'Dashboard'
    },
    {
      href: '/app/module-tasks',
      icon: AssignmentIcon,
      title: 'My Tasks'
    },
    {
      href: '/app/projects',
      icon: FolderIcon,
      title: 'Projects'
    },
    {
      href: '/app/modules',
      icon: ViewModuleIcon,
      title: 'Modules'
    },
    {
      href: '/app/calendar',
      icon: EventNoteIcon,
      title: 'Calendar'
    },
    {
      href: '/app/account',
      icon: AccountCircleIcon,
      title: 'Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: 'Settings'
    },
  ];

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <motion.div initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={baseURL+`${userAccount.avatar}`}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {userAccount.email}
        </Typography>

        <div
          style={{marginTop: '4%'}}
        >
          <TextField
            size="small"
            select
            label="status"
            value={userAccount.status}
            onChange={(e)=>handleUpdateAccount({"status":e.target.value})}
            variant="outlined"
          >
            {STATUS.map((option, i) => (
              <MenuItem key={i} value={option.status} dense>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" style={{color: option.color}} />
                </ListItemIcon>
                {option.status}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Box>
        </motion.div>
      <Divider />
      <Box p={2}>
        <List>
          {((userAccount.is_admin)?Adminitems:items)?.map((item,i) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: `0.${i}` } }}>
            <NavItem
              href={item.href}
              key={i}
              title={item.title}
              icon={item.icon}
            />
            </motion.div>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
