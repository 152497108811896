import { createStore,applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import rootReducer from 'src/reducers/rootReducer';
import rootSaga from 'src/sagas/rootSaga'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware()

const reduxStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk,sagaMiddleware)));
export default reduxStore;

sagaMiddleware.run(rootSaga)
