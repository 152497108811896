import { takeEvery, call, put, delay } from "redux-saga/effects";
import { serverCallAxiosInstance } from 'src/services/serverCallAxiosInstance';

const getDataFromApi = async () =>{
    const res = await serverCallAxiosInstance.get('/api/account/user_notifications/')
    return res.data.data
}

function* fetchChunkData() {
  yield delay(3000)
  try {
    const apiData = yield call(getDataFromApi)
    yield put({type: 'GET_USER_NOTIFICATIONS_LIST_SUCCESS', payload: apiData});
  } catch (err) {
      console.log(err)
  }
}

export function* waitForFetchNotification() {
  yield takeEvery('FETCH_NOTIFICATION', fetchChunkData);
}