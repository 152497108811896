import React from 'react'
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import {LightTheme,DarkTheme} from 'src/theme';
import routes from 'src/routes';
import { useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

const App = () => {
  const config = useSelector((state:any) => {
    return state.config
  })
  const routing = useRoutes(routes);

  return (
      <ThemeProvider theme={config.theme==='dark'?DarkTheme:LightTheme}>
      <CssBaseline/>
        <GlobalStyles />
          {routing}
      </ThemeProvider>
    
  )
}

export default App
