import React,{useState,useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Chip,Menu} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    }
  },
  spacing :{
    marginTop: theme.spacing(1),
  }
}));

interface Props {
  name?: string;
  nameList?:any;
  handleFormData?:any;
  what?:string;
  is_admin?:boolean;
}

export default function ChipBtn({name,nameList,handleFormData,what,is_admin}:Props) {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleBgColor=(name?:string)=>{
    let color = ""
    switch (name) {
      case "No Progress":
        color = "#9c27b0"
        break;
      case "In Progress":
        color = "#ff9100"
        break;
      case "Completed":
        color = "#4caf50"
        break;
      case "Low":
        color = "#00b0ff"
        break;
      case "Medium":
        color = "#ff9100"
        break;
      case "High":
        color = "#f44336"
        break;
      case "Publish":
        color = "#ff9100"
        break;
      case "Draft":
        color = "#4caf50"
        break;
      case "Hold":
        color = "#00b0ff"
        break;
      case "Done":
        color = "#ff9100"
        break;
      case "Suspend":
        color = "#f44336"
        break;
    
      default:
        break;
    }
    return color
  }

  return (
    <div className={classes.root}>
      <Chip
        color="primary"
        size="small"
        label={name}
        clickable
        style={{backgroundColor:handleBgColor(name)}}
        onClick={is_admin?handleMenuOpen:()=>{console.log('clicked')}}
        ref={moreRef}
      />
      <Menu
        anchorEl={moreRef.current}
        onClose={handleMenuClose}
        open={openMenu}
      >

        {nameList?.map((item:any,i:number)=>(
        <div className={classes.spacing}>
          <Chip
            key={i}
            color="primary"
            size="small"
            label={item.name}
            clickable
            style={{backgroundColor:handleBgColor(item.name)}}
            onClick={()=>{handleFormData(what,item.name); handleMenuClose();}}
          />
        </div>
        ))}

      </Menu>
    </div>
  );
}
