import React from 'react'

const FileView = ({file}) => {
    return (
        <div>
            {file.map((item,i)=>(
              <img width="300px" height="250px" key={i} src={item.file} alt="" />
            ))}
        </div>
    )
}

export default FileView
