import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import configReducer from './configReducer';
import accountReducer from './accountReducer';
import calendarReducer from './calendarReducer';
import projectReducer from './projectReducer';
import taskReducer from './taskReducer';
import moduleReducer from './moduleReducer';
import dashboardReducer from './dashboardReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers({
  form: formReducer,
  config: configReducer,
  account: accountReducer,
  calendar: calendarReducer,
  report: reportReducer,
  dashboard: dashboardReducer,
  project:projectReducer,
  module:moduleReducer,
  task:taskReducer
});

export default rootReducer;