import React, { useEffect,useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import {motion} from "framer-motion"
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import {Logo, NotificationsPopover} from 'src/components';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useDispatch,useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  title: {
    marginLeft: 10,
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const notificationsRef = useRef(null);
  // const [notifications] = useState([]);
  const [theme, setTheme] = useState('default');
  const [openNotifications, setOpenNotifications] = useState(false);

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const notifications = useSelector((state) => {
    return state.account.notifications
  })
  const isLoggedIn = useSelector((state) => {
    return state.account.isLoggedIn
  })
  
  useEffect(() => {
    if(isLoggedIn){
      dispatch({type: 'FETCH_NOTIFICATION'});
    }
  }, [isLoggedIn,dispatch])

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
          <Logo />
          </motion.div>
        </RouterLink>
        <Typography variant="h6" className={classes.title}>
          DailyLabs
        </Typography>
        <Box flexGrow={1} />
        {theme==='dark'?
        <IconButton color="inherit" onClick={() =>{setTheme('default'); dispatch({type:'THEME_SELECTED',payload:'default'});}}>
          <Brightness7Icon />
        </IconButton>
        :
          <IconButton color="inherit" onClick={() =>{setTheme('dark'); dispatch({type:'THEME_SELECTED',payload:'dark'});}}>
            <Brightness4Icon />
          </IconButton>
        }
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={()=>{handleNotificationsOpen();dispatch({type: 'FETCH_NOTIFICATION'});}}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={()=>{dispatch({type: 'ACC_LOGOUT'});setTimeout(() => dispatch({type:'RESET_ALERT_MESSAGE'}), 2000);}}>
            <PowerSettingsNewIcon />
          </IconButton>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
