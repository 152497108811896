import { createMuiTheme, colors, responsiveFontSizes } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

let theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F8F8F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main:'#313C67'
    },
    secondary: {
      main: '#FF831F'
    },
    text: {
      primary: '#313C67',
      secondary: '#1D1B1B'
    },
    type: "light",
  },
  shadows,
  typography,
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    // MuiFormControl: {
    //   margin: 'dense',
    // },
    // MuiFormHelperText: {
    //   margin: 'dense',
    // },
    // MuiIconButton: {
    //   size: 'small',
    // },
    // MuiInputBase: {
    //   margin: 'dense',
    // },
    // MuiInputLabel: {
    //   margin: 'dense',
    // },
    MuiListItem: {
      dense: true,
    },
    // MuiOutlinedInput: {
    //   margin: 'dense',
    // },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    // MuiTextField: {
    //   margin: 'dense',
    // },
    MuiToolbar: {
      variant: 'dense',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },

    MuiIconButton: {
      sizeSmall: {
        marginLeft: 4,
        marginRight: 4,
        padding: 6,
      },
    },
  },  
});

theme = responsiveFontSizes(theme);

export default theme;
