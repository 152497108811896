const initState = {
    dashboardData: {},
    message: '',
    loading: false,
};

const dashboardReducer = (state = initState, action: any) => {
    switch (action.type) {
        case "DLOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "GET_DASHBOARD_DATA_SUCC": {
            state = {
                ...state,
                dashboardData: action.payload,
                message: 'success',
                loading: false
            };
            break;
        }

        case "ERROR": {
            state = {
                ...state,
                message: 'error',
                loading: false
            };
            break;
        }
     
        default:
            state = { ...state };
            break;
    }
    return state;
};
export default dashboardReducer;
