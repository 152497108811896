import React, {useState} from 'react'
import {
    TwitterPicker
} from 'react-color'


const ReactColorForm = ({input,bg_color}:any) => {
    const [color, setColor] = useState(bg_color?bg_color:'')

    const handleSetColor =(color:any)=>{
        setColor(color.hex)
        input.onChange(color.hex)
    }

    return (
        <>
                <TwitterPicker
                color={ color }
                onChangeComplete={ handleSetColor }
            />
        </>
    )
}

export default ReactColorForm
