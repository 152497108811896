import { serverCallAxiosInstance } from 'src/services/serverCallAxiosInstance';

const MESSAGE = {
    "acc_register_succ": "Account Register Success",
    "acc_login_succ": "Account Login Success",
    "acc_update_succ": "Account Update Success",
    "acc_not_found": "Account Not Found",
    "inactive_user": "In Active User, Please Verify Your Account From Admin",
    "invalidcredentials": "Invalid Credentials",
}

const ALERT = {
    "error": "error",
    "warning": "warning",
    "info": "info",
    "success": "success",
}

const loadingT = () => {
    return {
        type: 'LOADING_T',
    }
}

export const loadingF = () => {
    return {
        type: 'LOADING_F',
    }
}

export const alertMessageAction = (arg?: any) => {
    return {
        type: 'ALERT_MESSAGE',
        payload: arg
    }
}

export const authZohoCallbackAction = (dataObj: any) => {
    return async (dispatch: any) => {
        // try {
        //     dispatch(loadingT());
            console.log(dataObj)
            const res = await serverCallAxiosInstance.post('/api/zoho/connect/', dataObj);
            console.log(res)
            debugger;
            // const data = res.data
            // if (("message" in data)) {
            //     setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
            //     if (data.message === "Success") {
            //         dispatch({
            //             type: 'ACC_LOGIN_SUCC',
            //             payload: { "data": data, "message": MESSAGE.acc_login_succ, "alert": ALERT.success }
            //         })
            //         window.location.reload()
            //     } else if (data.message === "Error") {
            //         if (data.errmessage === "Invalid Credentials") {
            //             dispatch(alertMessageAction({ "message": MESSAGE.invalidcredentials, "alert": ALERT.error }));
            //         }else if (data.errmessage === "InActive") {
            //             dispatch(alertMessageAction({ "message": MESSAGE.inactive_user, "alert": ALERT.error }));
            //         } else {
            //             console.log("Not Found data")
            //         }
            //     } else {
            //         console.log("Not Found data")
            //     }
            // }
        // } catch (err) {
        //     // errLocalStorageClear_Reload();
        //     console.log(err);
        // }
    }
}

export const accountRegisterAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.post('/api/account/register/', dataObj);
            const data = res.data
            if (("message" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                if (data.message === "Success") {
                    dispatch({
                        type: 'ACC_REGISTER_SUCC',
                        payload: { "data": data, "message": MESSAGE.acc_register_succ, "alert": ALERT.success }
                    })
                } else if (data.message === "Error") {
                    dispatch(alertMessageAction({ "message": data.errmessage, "alert": ALERT.error }));
                } else {
                    dispatch(loadingF());
                }
            } else {
                dispatch(loadingF());
                console.log('Not Found data')
            }
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const accountLoginAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.post('/api/account/login/', dataObj);
            console.log(res)
            const data = res.data
            if (("message" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                if (data.message === "Success") {
                    dispatch({
                        type: 'ACC_LOGIN_SUCC',
                        payload: { "data": data, "message": MESSAGE.acc_login_succ, "alert": ALERT.success }
                    })
                    window.location.reload()
                } else if (data.message === "Error") {
                    if (data.errmessage === "Invalid Credentials") {
                        dispatch(alertMessageAction({ "message": MESSAGE.invalidcredentials, "alert": ALERT.error }));
                    }else if (data.errmessage === "InActive") {
                        dispatch(alertMessageAction({ "message": MESSAGE.inactive_user, "alert": ALERT.error }));
                    }else if (data.errmessagesocial === "Social") {
                        dispatch(alertMessageAction({ "message": 'Continue With ' + data.errmessage, "alert": ALERT.error }));
                    } else {
                        console.log("Not Found data")
                    }
                } else {
                    console.log("Not Found data")
                }
            }
        } catch (err) {
            dispatch(loadingF());
            console.log(err);
        }
    }
}

export const loadUserAccountAction = () => {
    return async (dispatch:any) => {
        try {
            const res = await serverCallAxiosInstance.get('/api/account/user_account/');
            dispatch({
                type: 'LOAD_USER_ACCOUNT_SUCCESS',
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: 'ERROR',
            })
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const accountUpdateAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.put('/api/account/user_account/update', dataObj);
            const data = res.data
            if (("message" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                if (data.message === "Update") {
                    dispatch({
                        type: 'ACC_UPDATE_SUCC',
                        payload: { "data": data.data, "message": MESSAGE.acc_update_succ, "alert": ALERT.success }
                    })
                }
            } else {
                window.location.reload()
            }
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const accountPasswordUpdateAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.patch('/api/account/set_password/', dataObj);
            const data = res.data
            if (("message" in data)) {
            setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
            if(data.message === 'Success'){
                dispatch({
                    type: 'PASSWORD_UPDATE_SUCCESS',
                    payload: res.data
                })
            } else if(data.message === 'Error'){
                dispatch(alertMessageAction({ "message": data.err_message, "alert": ALERT.warning }));
            }}
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const getUserListAction = () => {
    return async (dispatch:any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.get('/api/account/user_list/');
            dispatch({
                type: 'GET_USER_LIST_SUCCESS',
                payload: res.data
            })
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const getUserListUpdateAction = (id:number,dataObj:any) => {
    console.log(id, dataObj)
    return async (dispatch:any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.put(`/api/account/user_list_update/${id}`,dataObj);
            dispatch({
                type: 'GET_USER_LIST_UPDATE_SUCCESS',
                payload: res.data.data
            })
            setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const getActiveUserEmailListAction = () => {
    return async (dispatch:any) => {
        try {
            dispatch(loadingT());
            const res = await serverCallAxiosInstance.get('/api/account/active_user_email_list/');
            dispatch({
                type: 'GET_ACTIVE_USER_EMAIL_LIST_SUCCESS',
                payload: res.data
            })
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}


export const deleteUserAction = (id:number) => {
    return async (dispatch:any) => {
        try {
            dispatch({
                type: 'MTLOADING_TRUE'
            })
            const res = await serverCallAxiosInstance.delete(`/api/account/user/${id}/delete`);
            dispatch({
                type: 'DELETE_USER_SUCCESS',
                payload: id
            })
            setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
        } catch (err) {
            dispatch(loadingF());
            console.log(err)
        }
    }
}

export const accountPasswordReseRequestAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            const res = await serverCallAxiosInstance.post('/api/account/request-reset-email/', dataObj);
            const data =  res.data
            if (("message" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                if(data.message === 'Success'){
                    dispatch(alertMessageAction({ "message": data.succ_message, "alert": ALERT.success }));
                } else if(data.message === 'Error'){
                    dispatch(alertMessageAction({ "message": data.err_message, "alert": ALERT.warning }));
                }}
        } catch (err) {
            console.log(err);
        }
    }
}

export const accountPasswordSetNewAction = (dataObj: any) => {
    return async (dispatch: any) => {
        try {
            const res = await serverCallAxiosInstance.patch('/api/account/password-reset-complete/', dataObj);
            const data =  res.data
            if (("message" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                dispatch(alertMessageAction({ "message": data.succ_message, "alert": ALERT.success }));
            }
            if (("detail" in data)) {
                setTimeout(() => {dispatch({type:'RESET_ALERT_MESSAGE'})}, 6000);
                dispatch(alertMessageAction({ "message": data.detail, "alert": ALERT.error }));
            }
            setTimeout(() => window.location.href = '/', 3000);
        } catch (err) {
            console.log(err);
        }
    }
}