const initState = {
    moduleList: {},
    moduleDetail: {},
    message: '',
    alert: '',
    loading: false,
};

const moduleReducer = (state:any = initState, action: any) => {
    switch (action.type) {
        case "MLOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "MRESET_ALERT_MESSAGE": {
            state = {
                ...state,
                message: '',
                alert: '',
                loading: false
            };
            break;
            }
        case "GET_MODULE_LIST_SUCCESS": {
            state = {
                ...state,
                moduleList: action.payload,
                loading: false
            };
            break;
        }
        case "GET_MODULE_DETAIL_SUCCESS": {
            state = {
                ...state,
                moduleDetail: action.payload,
                loading: false
            };
            break;
        }
        case "CLICKED_ON_MODULE": {
            state = {
                ...state,
                moduleDetail: action.payload,
                loading: false
            };
            break;
        }
        case "CREATE_MODULE_SUCCESS": {
            const data  = action.payload
            state.moduleList.results = [data, ...state.moduleList.results]
            state = {
                ...state,
                message: 'Module  Create Success',
                alert: 'success',
                loading: false  
            };
            break;
            }
        case "UPDATE_MODULE_SUCCESS": {
            const data = action.payload
            state.moduleList.results = state.moduleList.results.map((item:any)=>item.module_id===data.module_id ? data : item)
            state = {
                ...state,
                message: 'Module  Update Success',
                alert: 'success',
                loading: false  
            };
            break;
        }
        case "DELETE_MODULE_SUCCESS": {
            state.projectList.results = state.projectList.results.filter((item:any)=>item.module_id!==action.payload)
            state = {
                ...state,
                message: 'Module Delete Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "MODULE_ERROR": {
            state = {
                ...state,
                message: 'error',
                loading: false
            };
            break;
        }
     
        default:
            state = { ...state };
            break;
    }
    return state;
};
export default moduleReducer;
