const initState = {
    taskList: [],
    taskDetail: {},
    message: '',
    alert: '',
    loading: false,
};

const taskReducer = (state:any = initState, action: any) => {
    switch (action.type) {
        case "MTLOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "TRESET_ALERT_MESSAGE": {
        state = {
            ...state,
            message: '',
            alert: '',
            loading: false
        };
        break;
        }
        case "GET_TASK_LIST_SUCCESS": {
            state = {
                ...state,
                taskList: action.payload,
                loading: false
            };
            break;
        }
        case "CLICKED_ON_MODULE_TASK": {
            state = {
                ...state,
                taskDetail: action.payload,
                loading: false
            };
            break;
        }
        case "CREATE_TASK_SUCCESS": {
            const data = action.payload
            state = {
                ...state,
                taskList:[data, ...state.taskList],
                message: 'Task Create Success',
                alert: 'success',
                loading: false  
            };
            break;
        }
        case "UPDATE_MY_TASK_SUCCESS": {
            const data = action.payload
            state = {
                ...state,
                taskList: state.taskList.map((item:any)=>item.module_task_id===data.module_task_id ? data : item),
                message: 'Task  Update Success',
                alert: 'success',
                loading: false  
            };
            break;
        }
        case "DELETE_TASK_SUCCESS": {
            state = {
                ...state,
                taskList: state.taskList.filter((item:any)=>item.module_task_id!==action.payload),
                message: 'Task Delete Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "TASK_ERROR": {
            state = {
                ...state,
                message: 'error',
                loading: false
            };
            break;
        }
     
        default:
            state = { ...state };
            break;
    }
    return state;
};
export default taskReducer;
