import axios from 'axios';
import Cookie from 'universal-cookie'

let BASEURL = ""

if(process.env.NODE_ENV === "development"){
    BASEURL = "http://localhost:8000"
}

if(process.env.NODE_ENV === "production"){
    BASEURL = "https://admin.dailylabs.rightfullabs.com"
}

const cookie = new Cookie();
const token = cookie.get('token')

export const baseURL=BASEURL

export const serverCallAxiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
	  Authorization: token? "Bearer " + token.access: null,
	  "Content-Type": "application/json",
	  accept: "application/json",
	},
  });
  
  serverCallAxiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + '/api/account/token/refresh/'
		) {
			cookie.remove('token', { path: '/' });
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return serverCallAxiosInstance
						.post('/api/account/token/refresh/', { refresh: refreshToken }) //repeat server call problem not complete nik...
						.then((response) => {
							cookie.set('token', {"access":response.data.access,"refresh":response.data.refresh}, { path: '/' })

							serverCallAxiosInstance.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;

							return serverCallAxiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					cookie.remove('token', { path: '/' });
					localStorage.clear();
					sessionStorage.clear();
					window.location.href = '/login/';
				}
			} else {
				console.log('Refresh token not available.');
				cookie.remove('token', { path: '/' });
				localStorage.clear();
				sessionStorage.clear();
				window.location.href = '/login/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
)