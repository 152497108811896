const initState = {
    reportList: [],
    taskHisory: {},
    task:false,
    message: '',
    alert: '',
    searchQ: '',
    loading: false
}

const reportReducer = (state:any = initState, action: any) => {
    switch (action.type) {
        case "REPORT_LOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "RRESET_ALERT_MESSAGE": {
        state = {
            ...state,
            message: '',
            alert: '',
            loading: false
        };
        break;
        }
        case "GET_USER_REPORT_LIST_SUCCESS": {
            state = {
                ...state,
                reportList: action.payload,
                task:false,
                loading: false
            };
            break;
        }
        case "GET_TASK_REPORT_LIST_SUCCESS": {
            state = {
                ...state,
                reportList: action.payload,
                task:true,
                loading: false
            };
            break;
        }
        case "GET_TASK_REPORT_HISTORY_SUCCESS": {
            state = {
                ...state,
                taskHisory: action.payload,
                loading: false
            };
            break;
        }
        case "REPORT_SEARCH_Q": {
            state = {
              ...state,
              searchQ: action.payload
            };
            break;
          }
        default:
            state = { ...state };
            break;
    }
    return state;
};
export default reportReducer;

