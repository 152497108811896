import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom'
import {loadUserAccountAction} from 'src/actions/accountActions'

const AuthCheckIsLoggedIn = (props:any) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const isLoggedIn = useSelector((state:any) => state.account.isLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login',{replace:true});
        } else {
            dispatch(loadUserAccountAction())
        }
    }, [dispatch,navigate,isLoggedIn])

    return (
        <React.Fragment>
           {props.children}
        </React.Fragment>
    )
}

export default AuthCheckIsLoggedIn
