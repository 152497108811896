import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function HandleAlert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbars = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }
        dispatch({type:'CRESET_ALERT_MESSAGE'});
        setOpen(false);
    };

    const calendarR = useSelector((state:any) => {
        return state.calendar
    })

    useEffect(() => {
        if (calendarR.alert !== ''){
            setOpen(true);
        }
    },[calendarR,navigate])

    return (    
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <HandleAlert onClose={handleClose} severity={calendarR.alert}>
            {calendarR.message}
            </HandleAlert>
        </Snackbar>
    );
}

export default Snackbars;
