import React, {useState,useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FilesDropzone, RichEditor, ReactColorForm } from 'src/components'
import { Card, CardHeader, CardContent } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getActiveUserEmailListAction} from 'src/actions/accountActions'
  

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  spc: {
    margin: theme.spacing(1),
  },
  spcTop: {
    marginTop: theme.spacing(1),
  },
}));

const CreateUpdateForm = (props) => {
  const classes = useStyles();
  //   let navigate = useNavigate();

  const { handleSubmit,invalid, handleClose,goSubmitCreate,goSubmitUpdate,title,loading,update_id,what,nameplaceholder,namefield,detailData,project_id,module_id,tstatus,createStatusFlagForm } = props
  
  const description = what==="Update"?detailData.description:''
  let start_date = what==="Update"?detailData.start_date:''
  let end_date = what==="Update"?detailData.end_date:''
  const bg_color = what==="Update"?detailData.bg_color:''


  const dispatch = useDispatch()

  const userEmailList = useSelector((state) => {
    return state.account.userEmailList
  })

  const [files, setFiles] = useState([]);

  const [name, setName] = useState(what==="Update"?detailData.name:"");
  const [priority, setPriority] = useState(what==="Update"?detailData.priority:"");
  const [progress, setProgress] = useState(what==="Update"?detailData.progress:"");
  const [status, setStatus] = useState(what==="Update"?detailData.status:"");
  const [assignTo, setAssignTo] = useState(what==="Update"?detailData.assign_to:[]);
  const [notifications, setNotifications] = useState(what==="Update"?detailData.notifications:[]);

  const [selectedStartDate, setStartSelectedDate] = React.useState(
    what==="Update" ? detailData.start_date:null
  );

  const [selectedEndDate, setEndSelectedDate] = React.useState(
    what==="Update" ? detailData.end_date:null
  );

  if(what==="Update"){
  start_date =selectedStartDate
  end_date =selectedEndDate
  }

  const priorityOptions = [
    {
      "priority": "Low"
    },
    {
      "priority": "Medium"
    },
    {
      "priority": "High"
    }
]

const statusOptions = tstatus?[
    {
      "status": "List"
    },
    {
      "status": "In Progress"
    },
    {
      "status": "Completed"
    },
  ]:
  [
    {
      "status": "Publish"
    },
    {
      "status": "Draft"
    },
    {
      "status": "Hold"
    },
    {
      "status": "Done"
    },
    {
      "status": "Suspend"
    }
  ]
  
  const progressOptions = [
    {
      "progress": "No Progress"
    },
    {
      "progress": "In Progress"
    },
    {
      "progress": "Completed"
    }
  ]

  const handleStartDateChange = (date) => {
    setStartSelectedDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndSelectedDate(date);
  };

    useEffect(() => {
        dispatch(getActiveUserEmailListAction())
        if(createStatusFlagForm){
          setStatus(createStatusFlagForm);
        }
    }, [dispatch,createStatusFlagForm])

  const handleFormData = async (data)=>{
    let formData = new FormData();
    if(project_id){
      formData.append('project', project_id)
    }
    if(module_id){
      formData.append('module', module_id)
    }

    formData.append(namefield, name)
    if(data.description){
      formData.append('description', data.description)
    }
    if(progress){
      formData.append('progress', progress)
    }
    if(status){
      formData.append('status', status)
    }
    if(priority){
      formData.append('priority', priority)
    }
    try {
      if(selectedStartDate){
        formData.append('start_date',selectedStartDate.toJSON())
      }
    } catch {}
    try {
      if(selectedEndDate){
        formData.append('end_date',selectedEndDate.toJSON())
      }
    } catch {}
    if(assignTo&&assignTo.length>0){
      assignTo.map((i)=>
        formData.append('assign_to', Number(i.id))
      )
    } else {
      formData.append('assign_to_clear', 'clear')
    }
    if(notifications&&notifications.length>0){
      notifications.map((i)=>
        formData.append('notifications', Number(i.id))
      )
    } else {
      formData.append('notifications_clear', 'clear')
    }
    if(data.bg_color!==""&&data.bg_color!==undefined){
      formData.append('bg_color', data.bg_color)
    } else{
      formData.append('bg_color', "FAFAFA") 
    }
    files.map((file)=>
      formData.append('file', file)
    )

    if(name){
        if(what==='Create'){
            await goSubmitCreate(formData);
        }else{
            await goSubmitUpdate(update_id,formData);
        }
        setTimeout(() => {
          handleClose();
        }, 200);
    } else {
        alert('Fill the form')
    }
  }


  return (
    <PerfectScrollbar>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12}>
        <div className={classes.paper}>
        <Typography component="h1" variant="h3">
            {title}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit((data) => handleFormData(data))}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <TextField
                      variant="outlined"
                      autoFocus
                      required
                      name="fldname"
                      fullWidth
                      label={nameplaceholder}
                      defaultValue={name}
                      type='text'
                      autoComplete={false}
                      onChange={(e)=>setName(e.target.value)}
                    />
                  </CardContent>

                  <CardContent>
                    <Field
                        name="description"
                        placeholder="Description"
                        val={description}
                        component={RichEditor}
                    />
                  </CardContent>

                </Card>
              </Grid>

                <Grid item xs={4}>
                  <TextField
                    select
                    label="Priority"
                    fullWidth
                    value={priority}
                    onChange={(e)=>setPriority(e.target.value)}
                    // helperText="Please select option's"
                    variant="outlined"
                  >
                    {priorityOptions.map((option, i) => (
                      <MenuItem key={i} value={option.priority}>
                        {option.priority}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label="Progress"
                    fullWidth
                    value={progress}
                    onChange={(e)=>setProgress(e.target.value)}
                    variant="outlined"
                  >
                    {progressOptions.map((option, i) => (
                      <MenuItem key={i} value={option.progress}>
                        {option.progress}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label="Status"
                    fullWidth
                    value={status}
                    onChange={(e)=>setStatus(e.target.value)}
                    variant="outlined"
                  >
                    {statusOptions.map((option, i) => (
                      <MenuItem key={i} value={option.status}>
                        {option.status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      margin="normal"
                      fullWidth
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={what==="Update"?start_date:selectedStartDate}
                    //   value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      margin="normal"
                      fullWidth
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={what==="Update"?end_date:selectedEndDate}
                    //   value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid item xs={6} className={classes.spcTop}>
                  <Autocomplete
                    multiple
                    size="small"
                    onChange={(event, value) => setAssignTo(value)}
                    options={userEmailList}
                    getOptionLabel={(option) => option.email}
                    defaultValue={assignTo}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Assign To"
                        placeholder="User's"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} className={classes.spcTop}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={userEmailList}
                    getOptionLabel={(option) => option.email}
                    defaultValue={notifications}
                    filterSelectedOptions
                    onChange={(event, value) => setNotifications(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Notifications"
                        placeholder="No followers"
                      />
                    )}
                  />
                </Grid>

              <Grid item xs={8}>
                <Card>
                  <CardHeader
                    avatar={
                      <AttachFileIcon color="primary" />
                    }
                    title="Attach Files" />
                  <CardContent>
                  <Field
                        name="file"
                        files={files}
                        setFiles={setFiles}
                        component={FilesDropzone}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={4}>
              <Card>
                  <CardHeader
                    avatar={
                      <ColorLensIcon color="primary" />
                    }
                    title="Project Color" />
                  <CardContent>

                  <Field
                      name="bg_color"
                      bg_color={bg_color}
                      component={ReactColorForm}
                    />
                </CardContent>
                </Card>
              </Grid>

            </Grid>
            <Grid item xs={12} className={classes.spcTop}>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading ? true : invalid ? true : false}
                  >
                    {what}
                </Button>
                  <Button className={classes.spc} variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
    </PerfectScrollbar>
  );
}

export default reduxForm({
  form: 'CreateUpdateForm',
  //   validate: validations
})(CreateUpdateForm)
