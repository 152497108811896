const initState = {
    theme: 'default'
  };
  
  const configReducer = (state:any = initState, action: any) => {
    switch (action.type) {
      case "THEME_SELECTED": {
        state = {
          ...state,
          theme: action.payload
        };
        break;
      }
  
      default: {
        state = { ...state };
        break;
      }
    }
    return state;
  };
  export default configReducer;
  