import Cookie from 'universal-cookie';

const cookie = new Cookie();
const cookietoken = cookie.get('token')

const initState = {
  token: cookietoken,
  isLoggedIn: cookietoken ? true : false,
  userAccount: JSON.parse(localStorage.getItem("userAccount") || '{}'),
  message: '',
  alert: '',
  loading: false,
  routeTo: 0,
  userList: [],
  userEmailList: [],
  notifications:[],
  searchQ: ''
};

const accountReducer = (state:any = initState, action: any) => {
  switch (action.type) {
    case "ALERT_MESSAGE": {
      const { message, alert } = action.payload
      localStorage.setItem('message', message)
      localStorage.setItem('alert', alert)
      state = {
        ...state,
        message: message,
        alert: alert,
        loading: false
      };
      break;
    }

    case "RESET_ALERT_MESSAGE": {
      state = {
        ...state,
        message: '',
        alert: '',
        loading: false
      };
      break;
    }

    case "LOADING_T": {
      state = {
        ...state,
        loading: true
      };
      break;
    }

    case "LOADING_F": {
      state = {
        ...state,
        loading: false
      };
      break;
    }
    case "ROUTE_TO_F": {
      state = {
        ...state,
        routeTo: 0
      };
      break;
    }
    case "ACC_SEARCH_Q": {
      state = {
        ...state,
        searchQ: action.payload
      };
      break;
    }

    case "ACC_LOGIN_SUCC": {
      const { data, message, alert } = action.payload
      cookie.set('token', data.tokens, { path: '/' })
      localStorage.setItem('message', message)
      localStorage.setItem('alert', alert)
      state = {
        ...state,
        isLoggedIn: true,
        token: data.tokens,
        // userAccount: userAccount,
        message: message,
        alert: alert,
        routeTo: 2,
        loading: false
      };
      break;
    }

    case "ACC_REGISTER_SUCC": {
      const { data, message, alert } = action.payload
      const userAccount = {"email": data.email}
      localStorage.setItem('userAccount', JSON.stringify(userAccount))
      localStorage.setItem('message', message)
      localStorage.setItem('alert', alert)
      state = {
        ...state,
        message: message,
        userAccount:userAccount,
        alert: alert,
        routeTo: 1,
        loading: false
      };
      break;
    }

    case "LOAD_USER_ACCOUNT_SUCCESS": {
      const userAccount = action.payload
      localStorage.setItem('userAccount', JSON.stringify(userAccount))
      state = {
        ...state,
        userAccount: userAccount,
        loading: false
      };
      break;
    }

    case "GET_USER_LIST_SUCCESS": {
      state = {
        ...state,
        userList: action.payload,
        loading: false
      };
      break;
    }

    case "GET_USER_LIST_UPDATE_SUCCESS": {
      const data = action.payload
      state = {
        ...state,
        userList: state.userList.map((item:any)=>item.id===data.id ? data : item),
        message:'Update Success',
        alert: 'success',
        loading: false
      };
      break;
    }

    case "GET_ACTIVE_USER_EMAIL_LIST_SUCCESS": {
      state = {
        ...state,
        userEmailList: action.payload,
        loading: false
      };
      break;
    }
    case "GET_USER_NOTIFICATIONS_LIST_SUCCESS": {
      state = {
        ...state,
        notifications: action.payload,
        loading: false
      };
      break;
    }
    case "ACC_UPDATE_SUCC": {
      const { data,message,alert } = action.payload
      const userAccount = data
      localStorage.setItem('userAccount', JSON.stringify(userAccount))
      state = {
        ...state,
        userAccount: userAccount,
        message:message,
        alert:alert,
        loading: false
      };
      break;
    }
    case "DELETE_USER_SUCCESS": {
      state = {
          ...state,
          userList: state.userList.filter((item:any)=>item.id!==action.payload),
          message: 'User Delete Success',
          alert: 'success',
          loading: false
      };
      break;
    }
    case "PASSWORD_UPDATE_SUCCESS": {
      state = {
          ...state,
          message: 'Password Update Success',
          alert: 'success',
          loading: false
      };
      break;
    }
    case "ACC_LOGOUT": {
      cookie.remove('token', { path: '/' });
      localStorage.clear();
      sessionStorage.clear();
      state = {
        ...state,
        token: null,
        isLoggedIn: false,
        userAccount: {},
        message: 'Logout',
        alert: 'success',
        loading: false
      };
      break;
    }

    default: {
      state = { ...state };
      break;
    }
  }
  return state;
};
export default accountReducer;
