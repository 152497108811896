const initState = {
    projectList: {},
    projectDetail: {},
    message: '',
    alert: '',
    loading: false,
}

const projectReducer = (state:any = initState, action: any) => {
    switch (action.type) {
        case "PROJECT_LOADING_TRUE": {
            state = {
                ...state,
                loading: true
            };
            break;
        }
        case "PRESET_ALERT_MESSAGE": {
        state = {
            ...state,
            message: '',
            alert: '',
            loading: false
        };
        break;
        }
        case "GET_PROJECT_LIST_SUCCESS": {
            state = {
                ...state,
                projectList: action.payload,
                loading: false
            };
            break;
        }
        case "GET_PROJECT_DETAIL_SUCCESS": {
            state = {
                ...state,
                projectDetail: action.payload,
                loading: false
            };
            break;
        }
        case "CLICKED_ON_PROJECT": {
            state = {
                ...state,
                projectDetail: action.payload,
                loading: false
            };
            break;
        }
        case "CREATE_PROJECT_SUCCESS": {
            const data = action.payload
            state.projectList.results = [data, ...state.projectList.results]
            state = {
                ...state,
                message: 'Project Create Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "UPDATE_PROJECT_SUCCESS": {
            const data = action.payload
            state.projectList.results = state.projectList.results.map((item:any)=>item.project_id===data.project_id ? data : item)
            state = {
                ...state,
                message: 'Project Update Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "DELETE_PROJECT_SUCCESS": {
            state.projectList.results = state.projectList.results.filter((item:any)=>item.project_id!==action.payload)
            state = {
                ...state,
                message: 'Project Delete Success',
                alert: 'success',
                loading: false
            };
            break;
        }
        case "PROJECT_DETAIL_CLEAR": {
            state = {
                ...state,
                projectDetail: {},
                loading: false
            };
            break;
        }
        case "PROJECT_ERROR_MESSAGE": {
            const { errmessage, alert } = action.payload
            state = {
                ...state,
                message: errmessage,
                alert: alert,
                loading: false
            };
            break;
        }
        case "PROJECT_ERROR": {
            state = {
                ...state,
                message: 'error',
                loading: false
            };
            break;
        }

        default:
            state = { ...state };
            break;
    }
    return state;
};
export default projectReducer;

