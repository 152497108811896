import Lottie from 'react-lottie';
import nik from './nik.json'

const Auth = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: nik,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (

        <Lottie
            options={defaultOptions}
            style={{marginTop:'20%'}}
            height={400}
            width={400}
        />

    );
}

export default Auth