import React, {lazy,Suspense} from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LazyLoader from 'src/components/LazyLoader'
// import AuthZohoCallback from 'src/views/auth/AuthZohoCallback'

const Loadable = (Component) => props => (
  <Suspense fallback={<LazyLoader/>}>
    <Component {...props} />
  </Suspense>
);

const ForgotPassword = Loadable(lazy(() => import('src/views/auth/ForgotPassword')));
const SetnewPasswordCallback = Loadable(lazy(() => import('src/views/auth/SetnewPasswordCallback')));
const LoginView = Loadable(lazy(() => import('src/views/auth/LoginView')));
const RegisterView = Loadable(lazy(() => import('src/views/auth/RegisterView')));

const AccountView = Loadable(lazy(() => import('src/views/account/AccountView')));
const MyTasksView = Loadable(lazy(() => import('src/views/moduletask/MyTasksView')));
const Organization = Loadable(lazy(() => import('src/views/Organization')));
const MoreReports = Loadable(lazy(() => import('src/views/MoreReports')));
const DashboardView = Loadable(lazy(() => import('src/views/dashboard/DashboardView')));
const ProjectListView = Loadable(lazy(() => import('src/views/projects/ProjectListView')));
const Module = Loadable(lazy(() => import('src/views/Module')));

const EmployeesListView = Loadable(lazy(() => import('src/views/employees/EmployeesListView')));
const NotFoundView = Loadable(lazy(() => import('src/views/errors')));
const SettingsView = Loadable(lazy(() => import('src/views/settings')));
const Calendar = Loadable(lazy(() => import('src/views/Calendar')));

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <DashboardView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'callback/zoho/', element: <AuthZohoCallback /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'set-new-password', element: <SetnewPasswordCallback /> },
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
    ]
  },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'organization', element: <Organization /> },
      { path: 'reports', element: <MoreReports /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'projects', element: <ProjectListView /> },
      { path: 'modules', element: <Module /> },
      { path: 'module-tasks', element: <MyTasksView /> },
      { path: 'employees', element: <EmployeesListView /> },
      { path: 'calendar', element: <Calendar /> },
      { path: 'settings', element: <SettingsView /> },
    ]
  }
];

export default routes;